<template>
    <div class="modal-images">
        <div class="icon-wrapper" @click="$emit('close')">
            <i class="material-icons close-modal">close</i>
        </div>

        <Carousel ref="carousel" :per-page="1" :navigate-to="[options.selectedIndex, false]">
            <Slide :key="idx" v-for="(image, idx) in images">
                <div>
                    <img class="img-cover" :src="image" @error="$event.target.src = $blankProfile" />
                    <div class="watermark">
                        <div class="triangle">
                            <div class="one m-b-32">
                                {{ phoneNumber }}
                            </div>
                            <div class="two">
                                <div class="m-r-40">
                                    {{ phoneNumber }}
                                </div>
                                <div>
                                    {{ phoneNumber }}
                                </div>
                            </div>
                        </div>
                        <div class="triangle">
                            <div class="two m-b-32">
                                <div class="m-r-40">
                                    {{ phoneNumber }}
                                </div>
                                <div>
                                    {{ phoneNumber }}
                                </div>
                            </div>
                            <div class="one" style="">
                                {{ phoneNumber }}
                            </div>
                        </div>
                    </div>
                </div>
            </Slide>
        </Carousel>
    </div>
</template>

<script>
export default {
    name: 'ModalImages',
    props: ['options'],
    computed: {
        images() {
            return this.options.images || []
        },
        phoneNumber() {
            return this.$store.getters.me.phone_number
        },
    },
    mounted() {
        this.$nativeBridge.postMessage({
            action: 'setBackgroundColor',
            value: '#000000',
        })

        this.$nativeBridge.postMessage({
            action: 'allowSwipeGesture',
            value: false,
        })
    },
    beforeDestroy() {
        this.$nativeBridge.postMessage({
            action: 'setBackgroundColor',
            value: '#FFFFFF',
        })

        this.$nativeBridge.postMessage({
            action: 'allowSwipeGesture',
            value: true,
        })
    },
}
</script>

<style lang="scss" scoped>
.watermark {
    color: rgba(255, 255, 255, 0.15);
    position: absolute;
    // width: 110%;
    // height: 110%;
    // top: -5%;
    // left: -5%;
    width: 60%;
    height: 60%;
    top: 20%;
    left: 20%;

    font-size: 14px;
    pointer-events: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transform: rotate(-45deg);

    .triangle {
        display: flex;
        flex-direction: column;

        .one {
            display: flex;
            justify-content: center;
        }

        .two {
            display: flex;
            justify-content: center;
        }
    }
}
</style>
